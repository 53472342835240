import './Filters.scss';
import { useMemo, useState, useEffect, useRef, useCallback } from 'react';
import * as Yup from 'yup';
import { AutocompleteRenderGetTagProps } from '@mui/material/Autocomplete';
import { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { lastDayOfMonth, addMonths, subDays, addDays } from 'date-fns';
import { useNavigate, useLocation } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import omitProps from 'lodash/omit';
import { FormHandles } from '@unform/core';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import setValueProp from 'lodash/set';
import getValueProp from 'lodash/get';
import { FilterOptionsState } from '@mui/material';
import LoadTargetRequest from '../../store/modules/Targets/Targets.actions';
import LoadChannelRequest from '../../store/modules/Channels/Channels.actions';
import LoadMarketRequest from '../../store/modules/Markets/Markets.actions';
import { TypeTV } from '../../services/shared/Api.service';
import {
  SaveFiltersFormData,
  SaveFiltersFormDataError,
  SetFiltersFormRef,
  RemoveFiltersFormError,
  SavePartialFormData
} from '../../store/modules/Filters/Filters.actions';
import handleErrors, { YupError } from '../../helpers/HandleErrors';
import {
  FiltersData,
  ClosedPeriodTypesEnum
} from '../../store/modules/Filters/Filters.types';
import validateFields from './Filters.validations';
import AutocompleteInput from '../Form/AutocompleteInput';
import FormContainer from '../Form/FormContainer';
import SelectInput from '../Form/SelectInput';
import DatePickerInput from '../Form/DatePickerInput';
import GetLimitsBaseAudience from '../../store/modules/BaseAudience/BaseAudience.actions';
import GetPrograms from '../../store/modules/Programs/Programs.actions';
import paths from '../../routes/paths';
import { useAppDispatch, useAppSelector, useAppMediaQuery } from '../../hooks';
import Loading from '../Loading';
import { ClearPlanningPrograms } from '../../store/modules/Planning/Planning.actions';
import { stringToDate } from '../../helpers/Dates';
import Information from '../Information';
import useFilters from './useFilters';
import { LocalStorageProps } from '../../helpers/LocalStorage';
import useFiltersRedux from './useFiltersRedux';
import { SaveSceneryPath } from '../../store/modules/Simulation/Simulation.actions';
import { isNotebook } from '../../helpers/Utils';
import { Market } from '../../store/modules/Markets/Markets.types';
import ButtonApp from '../ButtonApp';
import ModalPeriod from '../ModalPeriod/ModalPeriod';
import { FormClosedPeriod } from '../ModalPeriod/types';
import classesGTM from '../../config';
import useClosedPeriod from '../ModalPeriod/useClosedPeriod';
import filtersLocalStorage from './Filters.LocalStorage';
import { ChannelsTypes } from '../../store/modules/Channels/Channels.types';
import { TargetsTypes } from '../../store/modules/Targets/Targets.types';
// eslint-disable-next-line import/no-cycle
import { useScenarios } from '../Scenarios';
import { UpdateSimulation } from '../../store/modules/Simulations/Simulations.actions';

const allTypes = [
  ClosedPeriodTypesEnum.MONTH,
  ClosedPeriodTypesEnum.QUARTER,
  ClosedPeriodTypesEnum.SEMESTER,
  ClosedPeriodTypesEnum.CLOSED_YEAR
];

const Filters = () => {
  const matches = useAppMediaQuery('sm');
  const location = useLocation();
  const locState = location.state as { typeTV: TypeTV | undefined };

  const dispatch = useAppDispatch();
  const { getCurrentFilters } = useFilters();

  const currentFilters = getCurrentFilters();

  const actualFormRef = useRef<FormHandles>(null);

  const closedPeriodForm = useRef<FormClosedPeriod | undefined>();

  const filtersStorage = useRef<LocalStorageProps<FiltersData>>();

  const { simulation } = useAppSelector((state) => state);

  const { getCopyScenery } = useScenarios();

  const [openModal, setOpenModal] = useState(false);
  const [typeTV, setTypeTV] = useState<TypeTV | undefined>(
    currentFilters?.partialData?.typeTV || locState?.typeTV || undefined
  );
  const [disableCampaign, setDisableCampaign] = useState(true);
  const [disableAudience, setDisableAudience] = useState(true);
  const [marketSelected, setMarketSelected] = useState<Market | undefined>();
  const [disableChannels, setDisableChannels] = useState(true);
  const [dateFromAudience, setDateFromAudience] = useState<Date | null>(null);
  const [dateFromCampaign, setDateFromCampaign] = useState<Date | null>(
    new Date()
  );
  const [filters, setFilters] = useState<FiltersData | undefined>();
  const [channels, setChannels] = useState<ChannelsTypes[]>([]);
  const [targetsSelected, setTargetsSelected] = useState<TargetsTypes[]>([]);
  const [initialData, setInitialData] = useState<any | undefined>();
  const [reloadAutocomplete, setReloadAutocomplete] = useState(false);

  const { profile } = useAppSelector((state) => state.auth);

  const {
    currentBaseAudience,
    currentTargets,
    currentMarkets,
    currentChannels,
    isLoadedInputs,
    disableLoadAllInputs
  } = useFiltersRedux(typeTV);

  const { getAllOptionsClosedPeriod } = useClosedPeriod(typeTV);

  const navigate = useNavigate();

  const clearFormAudienceDates = () => {
    actualFormRef?.current?.clearField('dates.audience.to');
    actualFormRef?.current?.clearField('dates.audience.from');
  };

  const handleClearError = (names: string | string[]) => {
    const arrNames = [];
    if (typeof names === 'string') {
      arrNames.push(names);
    }
    if (Array.isArray(names)) {
      arrNames.push(...names);
    }
    arrNames.forEach((name) => {
      let formErros = actualFormRef.current?.getErrors();
      if (isEmpty(formErros)) {
        dispatch(RemoveFiltersFormError(simulation.currentSceneryId));
        return;
      }
      if (formErros && getValueProp(formErros, name)) {
        formErros = setValueProp(formErros, name, '');
      }

      if (formErros) {
        const isValid = Object.keys(formErros).reduce((acc, key, _, arr) => {
          const isValueEmpty = isEmpty(getValueProp(formErros, key));

          if (arr.length === 1 && isValueEmpty) {
            return true;
          }
          return acc && isValueEmpty;
        }, isEmpty(actualFormRef.current?.getFieldError(name)));

        if (isValid)
          dispatch(RemoveFiltersFormError(simulation.currentSceneryId));
      }
    });
  };

  const fillAudienceDate = (from: Date, to: Date) => {
    actualFormRef?.current?.setFieldValue('dates.audience.from', from);

    setDateFromAudience(from);

    actualFormRef?.current?.setFieldValue('dates.audience.to', to);

    handleClearError(['dates.audience.from', 'dates.audience.to']);
  };

  const today = useMemo(() => new Date(), []);
  const minDateBuy = useMemo(() => new Date(), []);
  const maxDateBuy = useMemo(() => {
    const threeMonths = addMonths(
      new Date(today.getFullYear(), today.getMonth(), 1),
      3
    );
    const lastDaythreeMonths = lastDayOfMonth(threeMonths);
    if (dateFromCampaign && dateFromCampaign < lastDaythreeMonths) {
      return subDays(dateFromCampaign, 1);
    }

    return lastDaythreeMonths;
  }, [dateFromCampaign, today]);

  const targetsOptions = useMemo(() => {
    if (currentTargets.data) {
      return currentTargets.data.map((target) => ({
        value: target.name,
        label: target.name
      }));
    }
    return [];
  }, [currentTargets.data]);

  const getClosedPeriodDataByAudiencePeriod = useCallback(
    (dateFrom: Date | undefined, dateTo: Date | undefined) => {
      if (dateFrom && dateTo) {
        const val = `${dateFrom.toJSON()}|${dateTo.toJSON()}`;

        const allOpts = getAllOptionsClosedPeriod(dateFrom.getFullYear());

        return allTypes.reduce<FormClosedPeriod | undefined>((acc, type) => {
          const value = allOpts[type].find((opt) => opt.value === val);

          if (value) {
            return {
              value,
              type,
              year: dateFrom
            };
          }

          return acc;
        }, undefined);
      }

      return undefined;
    },
    [getAllOptionsClosedPeriod]
  );

  const serializeData = useCallback(
    (data: FiltersData | Partial<FiltersData>) => {
      const cloneData = cloneDeep(data);
      if (cloneData) {
        const newData: any = { dates: {}, ...cloneData };
        if (data?.targets) {
          const targetsObj = data.targets.map((el) => ({
            value: el,
            label: el
          }));

          newData.targets = targetsObj;
        }

        if (data?.dates) {
          const { audience, campaign, buy } = data.dates;

          if (audience.from) {
            const date =
              audience.from instanceof Date
                ? audience.from
                : stringToDate(audience.from);

            setDateFromAudience(date);
            setDisableAudience(false);
            newData.dates.audience.from = date;
          }

          if (audience.to) {
            newData.dates.audience.to =
              audience.to instanceof Date
                ? audience.to
                : stringToDate(audience.to.toString());
          }

          closedPeriodForm.current = getClosedPeriodDataByAudiencePeriod(
            newData?.dates?.audience?.from,
            newData?.dates?.audience?.to
          );

          if (campaign.from) {
            const date =
              campaign.from instanceof Date
                ? campaign.from
                : stringToDate(campaign.from.toString());

            setDateFromCampaign(date);
            setDisableCampaign(false);

            newData.dates.campaign.from = date;
          }

          if (campaign.to) {
            newData.dates.campaign.to =
              campaign.to instanceof Date
                ? campaign.to
                : stringToDate(campaign.to.toString());
          }

          if (buy) {
            newData.dates.buy =
              buy instanceof Date ? buy : stringToDate(buy.toString());
          }
        }

        if (data?.channels) {
          newData.channels = data.channels
            .filter((el) =>
              currentChannels.data.some(
                (c) =>
                  c.description.toLowerCase() === el.description.toLowerCase()
              )
            )
            .map((channel) => omitProps(channel, 'id'));
        }

        if (data?.market) {
          const newMarket = currentMarkets?.data?.find(
            (market) => market?.signal === data?.market?.signal
          );

          newData.market = newMarket;
          setMarketSelected(newMarket);
        }

        return newData;
      }

      return {};
    },
    [currentChannels, currentMarkets.data, getClosedPeriodDataByAudiencePeriod]
  );

  useEffect(() => {
    const store = filtersLocalStorage(simulation.currentSceneryId);

    filtersStorage.current = store;
  }, [simulation.currentSceneryId]);

  useEffect(() => {
    if (channels.length) {
      actualFormRef?.current?.setFieldValue('channels', channels);
    }

    if (targetsSelected.length) {
      actualFormRef?.current?.setFieldValue('targets', targetsSelected);
    }

    const dataForm = actualFormRef.current?.getData();

    if (dataForm && dataForm?.typeTV !== '') {
      dispatch(SavePartialFormData(simulation.currentSceneryId, dataForm));
    }
  }, [channels, targetsSelected, dispatch, simulation.currentSceneryId]);

  useEffect(() => {
    const curFilters = getCurrentFilters();
    if (curFilters?.error) {
      /* actualFormRef.current?.setErrors(curFilters.erros || {}); */
    }
  }, [getCurrentFilters]);

  useEffect(() => {
    if (locState?.typeTV) setTypeTV(locState.typeTV);
  }, [locState]);

  useEffect(() => {
    if (isLoadedInputs) {
      const partialData = getCurrentFilters()?.partialData;

      if (filtersStorage.current && isEmpty(partialData) && !simulation.id) {
        const storedValue = filtersStorage.current.get();
        setFilters(storedValue);
      }
    }
  }, [
    simulation.id,
    simulation.currentScenery,
    getCurrentFilters,
    isLoadedInputs
  ]);

  useEffect(() => {
    if (!isLoadedInputs) return;

    const partialData = getCurrentFilters()?.partialData;

    if (partialData && isEmpty(initialData) && typeTV === partialData.typeTV) {
      setInitialData(serializeData(partialData));
    }
  }, [getCurrentFilters, serializeData, initialData, isLoadedInputs, typeTV]);

  useEffect(() => {
    if (filters && isLoadedInputs && isEmpty(initialData)) {
      setInitialData(serializeData(filters));
    }
  }, [filters, isLoadedInputs, serializeData, initialData]);

  useEffect(() => {
    if (disableLoadAllInputs) return;

    if (typeTV) {
      dispatch(LoadChannelRequest(typeTV));
      dispatch(LoadMarketRequest(typeTV));

      if (!currentBaseAudience.dateMax || !currentBaseAudience.dateMin) {
        dispatch(GetLimitsBaseAudience(typeTV));
      }
    }
  }, [
    dispatch,
    disableLoadAllInputs,
    currentBaseAudience.dateMax,
    currentBaseAudience.dateMin,
    typeTV
  ]);

  useEffect(() => {
    const currentSceneryId = simulation?.currentSceneryId;
    const currentScenery = simulation?.scenarios?.find(
      (sc) => sc?.uuid === currentSceneryId
    );
    const formRefData = actualFormRef?.current?.getData();
    const filtersData =
      getCurrentFilters()?.partialData || getCurrentFilters()?.data;

    if (
      currentScenery &&
      formRefData &&
      filtersData &&
      isLoadedInputs &&
      formRefData?.typeTV !== '' &&
      formRefData?.targets?.length > 0 &&
      formRefData?.channels?.length > 0
    ) {
      const hasPlanning = getCopyScenery(currentScenery)?.planning;
      const JSONFiltersData = JSON?.stringify(filtersData);
      const JSONFormRefData = JSON?.stringify(formRefData);

      if (hasPlanning && JSONFiltersData !== JSONFormRefData) {
        // nesse caso, o usuario buscou e rankeou, e depois voltou para a tela de filtros e alterou algo. Portanto, ele vai salvar um rascunho sem os programas
        const othersSceneries = simulation?.scenarios?.filter(
          (sc) => sc?.uuid !== currentSceneryId
        );
        const othersSceneriesWithFilters = othersSceneries.map((sc) => ({
          ...sc,
          ...getCopyScenery(sc)
        }));
        const newCurrentScenery = {
          ...currentScenery,
          filters: actualFormRef?.current?.getData(),
          planning: undefined
        };
        const sceneriesOrderedByScenaryNumber = [
          newCurrentScenery,
          ...othersSceneriesWithFilters
        ].sort((a, b) => a.sceneryNumber - b.sceneryNumber);

        const newSimulation = {
          ...simulation,
          scenarios: [...sceneriesOrderedByScenaryNumber]
        };

        simulation?.id &&
          dispatch(UpdateSimulation(newSimulation, simulation?.id, true));
        dispatch(
          SavePartialFormData(
            simulation?.currentSceneryId,
            actualFormRef?.current?.getData()
          )
        );
      }
    }
  }, [dispatch, getCopyScenery, simulation, getCurrentFilters, isLoadedInputs]);

  useEffect(() => {
    if (
      typeTV &&
      marketSelected &&
      (typeTV !== currentTargets.typeTV || !currentTargets.data.length)
    ) {
      dispatch(LoadTargetRequest(typeTV));
    }
  }, [
    dispatch,
    typeTV,
    marketSelected,
    currentTargets.typeTV,
    currentTargets.data.length
  ]);

  useEffect(() => {
    dispatch(
      SetFiltersFormRef({
        ref: actualFormRef,
        sceneryId: simulation.currentSceneryId
      })
    );
  }, [dispatch, simulation.currentSceneryId, actualFormRef]);

  const handleSetTypeTV = () => {
    if (profile?.role === 'AGENCY') {
      setTypeTV(TypeTV.OPEN);
    }
  };

  useEffect(() => {
    handleSetTypeTV();
  });

  const handleFormSubmit = async (formData: FiltersData) => {
    const { path, childrens } = paths.home.childrens.simulation;
    try {
      actualFormRef?.current?.setErrors({});

      await validateFields(formData);

      if (closedPeriodForm.current) {
        // set closed period form in filters form in "closedPeriod" field
        setValueProp(
          formData,
          'dates.audience.closedPeriod',
          closedPeriodForm.current
        );
      }

      dispatch(
        SaveFiltersFormData({
          data: formData,
          sceneryId: simulation.currentSceneryId
        })
      );
      dispatch(SavePartialFormData(simulation.currentSceneryId, formData));
      dispatch(GetPrograms());
      dispatch(ClearPlanningPrograms(simulation.currentSceneryId));

      if (!simulation.id) {
        filtersStorage.current?.set(formData);
      }

      dispatch(SaveSceneryPath(childrens.ranking));
      navigate(`${path}/${simulation.currentScenery}/${childrens.ranking}`);
    } catch (e) {
      let error: YupError | undefined;
      if (e instanceof Yup.ValidationError) {
        error = handleErrors(e);
        actualFormRef?.current?.setErrors(error);
      }
      dispatch(SaveFiltersFormDataError(simulation.currentSceneryId, error));
    }
  };

  const handleTypeTvSelect = (event: SelectChangeEvent) => {
    const type = event.target.value as TypeTV;
    actualFormRef?.current?.clearField('targets');
    actualFormRef?.current?.clearField('channels');
    actualFormRef?.current?.clearField('market');

    clearFormAudienceDates();

    setTypeTV(type);
  };

  const handleOptionsClosedPeriodsChange = (newValue: any | null) => {
    if (newValue) {
      const { value } = newValue;

      if (value) {
        const [dateFrom, dateTo] = value
          .split('|')
          .map((v: any) => new Date(v));

        fillAudienceDate(dateFrom, lastDayOfMonth(dateTo));
      }

      return;
    }

    clearFormAudienceDates();
  };

  const handleMarketChange = (value: Market | undefined) => {
    setMarketSelected(value);

    if (value?.minDate && value?.maxDate) {
      fillAudienceDate(
        stringToDate(value.minDate),
        stringToDate(value.maxDate)
      );
    }
  };

  const handleClosedPeriodClick = () => {
    const dataForm = actualFormRef.current?.getData();

    const dateFrom = dataForm?.dates?.audience?.from;
    const dateTo = dataForm?.dates?.audience?.to;

    if (dateFrom && dateTo) {
      closedPeriodForm.current = getClosedPeriodDataByAudiencePeriod(
        dateFrom,
        dateTo
      );
    }

    setOpenModal(true);
  };

  const handleModalClosedPeriodSubmit = (childData: FormClosedPeriod) => {
    clearFormAudienceDates();
    handleOptionsClosedPeriodsChange(childData.value);
    closedPeriodForm.current = childData;
    setOpenModal(false);
  };

  const handleFromCampaignChange = (newDate: Date | null) => {
    setDateFromCampaign(newDate);
    setDisableAudience(!newDate);
  };

  const handleToCampaignChange = (newDate: Date | null) => {
    setDisableAudience(!newDate);

    if (newDate) {
      const dataForm = actualFormRef.current?.getData();

      if (
        typeTV === TypeTV.CLOSED &&
        !dataForm?.dates?.audience?.to &&
        !dataForm?.dates?.audience?.from
      ) {
        setTimeout(() => setOpenModal(true), 250);
      }
    }
  };

  const handleChannelsChange = useCallback(
    (value: any) => {
      const dataForm = actualFormRef.current?.getData();

      if (
        channels &&
        channels.length !== value.length &&
        dataForm?.channels.length !== value.length
      ) {
        setChannels(value);
      }

      setDisableCampaign(!value?.length || !value);
    },
    [channels, setChannels, actualFormRef, setDisableCampaign]
  );

  const handleTargetsChange = (value: any) => {
    const dataForm = actualFormRef.current?.getData();

    if (
      targetsSelected &&
      targetsSelected.length !== value.length &&
      dataForm?.targets.length !== value.length
    ) {
      setTargetsSelected(value);
    }

    setDisableChannels(!value?.length || !value);
  };

  const renderTags = (value: any[], getTags: AutocompleteRenderGetTagProps) => {
    return value.map((chip: any, index: number) => {
      const props = getTags({ index });
      const labelAvatar = (idx: number) => `${idx + 1}º`;
      return (
        <Chip
          {...props}
          key={props.key}
          avatar={<Avatar>{labelAvatar(index)}</Avatar>}
          label={chip.label}
        />
      );
    });
  };

  const filteredChannelsOptions = currentChannels.data
    .filter((ch) => (isNotebook(marketSelected) ? isNotebook(ch) : ch))
    .filter((opt) =>
      typeTV === TypeTV.OPEN
        ? marketSelected?.channels?.includes(opt.description)
        : true
    );

  const filterMarkets = (
    options: Market[],
    { inputValue }: FilterOptionsState<any>
  ) =>
    options.filter(
      (opt) =>
        opt.signal.toLowerCase().search(inputValue.toLowerCase()) !== -1 ||
        opt.name.toLowerCase().search(inputValue.toLowerCase()) !== -1
    );
  useEffect(() => {
    const selectedChannel = [
      filteredChannelsOptions.find(
        (channel: any) => channel.description === 'TV GLOBO'
      )
    ];
    if (profile?.role === 'AGENCY' && filteredChannelsOptions?.length > 0) {
      handleChannelsChange(selectedChannel);
    }
  });

  useEffect(() => {
    if (
      currentMarkets.data[13] &&
      getCurrentFilters()?.partialData?.market?.signal !== 'SP1'
    ) {
      setReloadAutocomplete(true);
    }
  }, [currentMarkets.data, getCurrentFilters]);

  useEffect(() => {
    if (marketSelected === null) {
      const selectedMarket = currentMarkets.data.find(
        (channel: any) => channel.signal === 'SP1'
      );

      if (profile?.role === 'AGENCY' && currentMarkets.data?.length > 0) {
        setMarketSelected(selectedMarket);
      }
    }
  }, [marketSelected, currentMarkets.data, profile?.role]);

  return (
    <div className="simulation-infos">
      <h3>Filtros para o Planejamento</h3>
      <Information
        message={`Preencha os campos abaixo e pressione "Buscar e Rankear" para
        exibir o ranking.`}
      />
      <FormContainer
        error
        formRef={actualFormRef}
        fullWidth
        id={`simulation-form-${simulation.currentScenery}`}
        initialData={initialData}
        onSubmit={handleFormSubmit}
      >
        <div className="form-divisor d-flex flex-column">
          <label className="label-sub" htmlFor="label-title">
            TV, Praça, Targets e Canais
          </label>
          <div className="input-wrapper">
            <div className="input-wrapper__divisor_select">
              <div className="input-wrapper__divisor__column">
                {profile?.role === 'AGENCY' ? (
                  <SelectInput
                    label="TV"
                    name="typeTV"
                    {...(typeTV
                      ? { value: typeTV }
                      : { defaultValue: TypeTV.OPEN })}
                    onChange={handleTypeTvSelect}
                    onClearError={handleClearError}
                    options={[{ value: TypeTV.OPEN, label: 'Aberta' }]}
                    disabled
                    required
                  />
                ) : (
                  <SelectInput
                    label="TV Aberta / Fechada"
                    name="typeTV"
                    onChange={handleTypeTvSelect}
                    onClearError={handleClearError}
                    options={[
                      { value: TypeTV.OPEN, label: 'Aberta' },
                      { value: TypeTV.CLOSED, label: 'Fechada' }
                    ]}
                    required
                  />
                )}
              </div>
            </div>
          </div>
          <Information
            message={`Ao selecionar "TV Fechada", será necessário preencher o período de audiência clicando em "Período fechado".`}
            visible={typeTV === TypeTV.CLOSED}
          />
          <div className="input-wrapper">
            <div className="input-wrapper__divisor_select">
              <div className="input-wrapper__divisor__column">
                {profile?.role === 'AGENCY' ? (
                  <AutocompleteInput
                    data-testid="pracas"
                    dataClarityMask
                    defaultValue={marketSelected}
                    disabled
                    filterOptions={filterMarkets}
                    groupBy={(opt) => (isNotebook(opt) ? 'CADERNO' : 'REGULAR')}
                    label="Praça"
                    labelProp="name"
                    loading={currentMarkets.loading}
                    name="market"
                    onChange={handleMarketChange}
                    onClearError={handleClearError}
                    options={[currentMarkets.data[13]]}
                    renderOption={(props, option) => (
                      <li {...props}>
                        <ListItemText
                          primary={option.name}
                          secondary={option.signal}
                        />
                      </li>
                    )}
                    required
                    returnObject
                    valueProp="signal"
                    key={reloadAutocomplete ? 'reload' : 'no-reload'}
                  />
                ) : (
                  <AutocompleteInput
                    data-testid="pracas"
                    dataClarityMask
                    disabled={!typeTV}
                    filterOptions={filterMarkets}
                    groupBy={(opt) => (isNotebook(opt) ? 'CADERNO' : 'REGULAR')}
                    label="Praça"
                    labelProp="name"
                    loading={currentMarkets.loading}
                    name="market"
                    onChange={handleMarketChange}
                    onClearError={handleClearError}
                    options={currentMarkets.data}
                    renderOption={(props, option) => (
                      <li {...props}>
                        <ListItemText
                          primary={option.name}
                          secondary={option.signal}
                        />
                      </li>
                    )}
                    required
                    returnObject
                    valueProp="signal"
                  />
                )}
              </div>
            </div>
          </div>
          <div className="input-wrapper">
            <div className="input-wrapper__divisor_select">
              <div className="input-wrapper__divisor__column">
                <AutocompleteInput
                  data-testid="targets"
                  dataClarityMask
                  disableCloseOnSelect
                  label="Targets"
                  limitTags={2}
                  loading={currentTargets.loading}
                  maxItems={2}
                  multiple
                  name="targets"
                  onChange={handleTargetsChange}
                  onClearError={handleClearError}
                  options={targetsOptions}
                  renderTags={renderTags}
                  required
                />
              </div>
            </div>
          </div>
          <div className="input-wrapper">
            <div className="input-wrapper__divisor_select">
              <div className="input-wrapper__divisor__column">
                {profile?.role === 'AGENCY' &&
                filteredChannelsOptions?.length &&
                filteredChannelsOptions?.length > 0 ? (
                  <AutocompleteInput
                    data-testid="emissoras"
                    dataClarityMask
                    disableCloseOnSelect
                    multiple
                    disabled
                    defaultValue={[filteredChannelsOptions[0]]}
                    label=""
                    labelProp="description"
                    limitTags={1}
                    loading={currentChannels.loading}
                    name="channels"
                    onChange={handleChannelsChange}
                    onClearError={handleClearError}
                    options={[filteredChannelsOptions[0]]}
                    required
                    returnObject
                    valueProp="description"
                  />
                ) : (
                  <AutocompleteInput
                    data-testid="emissoras"
                    dataClarityMask
                    disableCloseOnSelect
                    disabled={disableChannels}
                    label="Canais"
                    labelProp="description"
                    limitTags={5}
                    loading={currentChannels.loading}
                    multiple
                    name="channels"
                    onChange={handleChannelsChange}
                    onClearError={handleClearError}
                    options={filteredChannelsOptions}
                    required
                    returnObject
                    valueProp="description"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="form-divisor">
          <label className="label-sub" htmlFor="label-title">
            Períodos e Datas
          </label>
          <div className="form-side">
            <div className="input-wrapper">
              <label className="label-title" htmlFor="label-title">
                Período de Campanha
              </label>
              <div className="input-wrapper__divisor">
                <Stack direction={!matches ? 'column' : 'row'} spacing={1}>
                  <DatePickerInput
                    defaultValue={today}
                    disabled={disableCampaign}
                    label="De"
                    minDate={addDays(minDateBuy, 1)}
                    name="dates.campaign.from"
                    onChange={handleFromCampaignChange}
                    onClearError={handleClearError}
                    required
                  />
                  <DatePickerInput
                    disabled={!dateFromCampaign || disableCampaign}
                    label="Até"
                    minDate={dateFromCampaign || undefined}
                    name="dates.campaign.to"
                    onChange={handleToCampaignChange}
                    onClearError={handleClearError}
                    required
                  />
                </Stack>
              </div>
            </div>
            <div className="input-wrapper">
              <label className="label-title" htmlFor="label-title">
                Período de Audiência
              </label>
              <div className="input-wrapper__divisor">
                <Stack direction={!matches ? 'column' : 'row'} spacing={1}>
                  <DatePickerInput
                    disabled={disableAudience || typeTV === TypeTV.CLOSED}
                    label="De"
                    loading={currentBaseAudience.loading}
                    maxDate={
                      currentBaseAudience.dateMax
                        ? currentBaseAudience.dateMax
                        : undefined
                    }
                    minDate={
                      currentBaseAudience.dateMin
                        ? currentBaseAudience.dateMin
                        : undefined
                    }
                    name="dates.audience.from"
                    onChange={setDateFromAudience}
                    onClearError={handleClearError}
                    required
                    showTodayButton
                  />
                  <DatePickerInput
                    disabled={
                      !dateFromAudience ||
                      disableAudience ||
                      disableChannels ||
                      typeTV === TypeTV.CLOSED
                    }
                    label="Até"
                    loading={currentBaseAudience.loading}
                    maxDate={
                      currentBaseAudience.dateMax
                        ? currentBaseAudience.dateMax
                        : undefined
                    }
                    minDate={
                      dateFromAudience ||
                      currentBaseAudience.dateMin ||
                      undefined
                    }
                    name="dates.audience.to"
                    onClearError={handleClearError}
                    required
                    showTodayButton
                  />
                </Stack>
              </div>
            </div>
            <ButtonApp
              classes={{
                button: 'btn--med btn--transparent',
                tagManager: classesGTM.filters.closedPeriod
              }}
              disabled={disableCampaign || disableAudience}
              onClick={handleClosedPeriodClick}
              title="Período fechado"
              visible
            />
            <Divider className="my-4" />
            <div className="input-wrapper">
              <label className="label-title" htmlFor="label-title">
                Data de Compra
              </label>
              <div className="input-wrapper__divisor_select">
                <div className="input-wrapper__divisor__column">
                  <DatePickerInput
                    defaultValue={today}
                    label="Compra"
                    maxDate={maxDateBuy}
                    minDate={minDateBuy}
                    name="dates.buy"
                    onClearError={handleClearError}
                    required
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </FormContainer>
      <ModalPeriod
        initialData={closedPeriodForm.current}
        onCancelClick={() => setOpenModal(false)}
        onModalSubmit={handleModalClosedPeriodSubmit}
        open={openModal}
        typeTV={typeTV}
      />
      {currentTargets.loading ||
      currentBaseAudience.loading ||
      currentChannels.loading ||
      currentMarkets.loading ? (
        <Loading />
      ) : null}
    </div>
  );
};

export default Filters;
