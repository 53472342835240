import hasProp from 'lodash/has';
import getValue from 'lodash/get';
import { utils, writeFile, WorkSheet, Range } from 'xlsx-js-style';
import ini from 'ini';
import moment from 'moment';
import { toast } from 'react-toastify';
import { GridColumn } from '../components/GridPagination/types';
import { MapCell, MapCol, MapColData } from '../xlsx/types';
import { PVIPlanningData } from '../components/Planning/usePlanningGAdsPvi';
import postExport, {
  postExportDigitalSimulation
} from '../services/MediaSimulator/Exports.service';

import { IDigitalSimulationExport } from '../services/MediaSimulator/Types/Export/ExportDigital.types';
import { SimulationProps } from '../store/modules/Simulation/Simulation.types';
import {
  GroupData,
  IGroup
} from '../store/modules/Digital/Groups/Groups.types';
import { CpmData } from '../store/modules/Digital/Cpm/Cpm.types';
import { FiltersData } from '../store/modules/Digital/Filters/Filters.types';
import { Age } from '../store/modules/Digital/Ages/Ages.types';
import { Gender } from '../store/modules/Digital/Genders/Genders.types';
import { Interest } from '../store/modules/Digital/Interests/Interests.types';
import { Location } from '../store/modules/Digital/Locations/Locations.types';

interface WorkSheetObj {
  [key: string]: any;
}

function getGridContent(
  columns: readonly GridColumn<unknown>[],
  rows: readonly unknown[]
) {
  const validCols = columns.filter(
    (col) =>
      col.key !== 'action' && (!hasProp(col, 'hideColumn') || !col.hideColumn)
  );

  const validData = rows.map((row) =>
    validCols.map((col) => {
      const val = col?.exportedField
        ? getValue(row, col.exportedField)
        : getValue(row, col.key);

      if (Array.isArray(val)) return val.join(', ');
      if (col.exportXlsxFormat && col.exportXlsxFormat.search('%')) {
        return val / 100 || '';
      }

      return val;
    })
  );

  return {
    head: validCols.map((col) => col.name.toString()),
    body: validData
  };
}

function serializeCellValue(value: unknown) {
  if (typeof value === 'string') {
    const formattedValue = value.replace(/"/g, '""');
    return formattedValue.includes(',')
      ? `"${formattedValue}"`
      : formattedValue;
  }
  return value;
}

export function downloadFile(fileName: string, data: Blob) {
  const downloadLink = document.createElement('a');
  downloadLink.download = fileName;
  const url = URL.createObjectURL(data);
  downloadLink.href = url;
  downloadLink.click();
  URL.revokeObjectURL(url);
}

function getXlxsColumnsName(worksheet: WorkSheet, col: number) {
  const range = utils.decode_range(worksheet['!ref'] || '');

  const xlsxNames = [];
  // eslint-disable-next-line no-plusplus
  for (let row = range.s.r + 1; row <= range.e.r; ++row) {
    const ref = utils.encode_cell({ r: row, c: col });

    if (worksheet[ref] && worksheet[ref].t === 'n') {
      xlsxNames.push(ref);
    }
  }

  return xlsxNames;
}

export async function exportToCsv(
  columns: readonly GridColumn<unknown>[],
  rows: readonly unknown[],
  fileName: string
) {
  const { head, body } = getGridContent(columns, rows);
  const content = [head, ...body]
    .map((cells) => cells.map(serializeCellValue).join(','))
    .join('\n');

  downloadFile(
    fileName,
    new Blob([content], { type: 'text/csv;charset=utf-8;' })
  );
}

function serializeSheetName(sheetName: string) {
  return sheetName.replace(/\\|\/|\?|\*|\[|\]/g, '');
}

export async function exportToXlsxWithMultipleSheets(
  data: {
    columns: readonly GridColumn<unknown>[];
    rows: readonly unknown[];
    sheetName: string;
  }[],
  fileName: string
) {
  const workBook = utils.book_new();
  data.forEach(({ columns, rows, sheetName }) => {
    const { head, body } = getGridContent(columns, rows);

    const workSheet = utils.aoa_to_sheet([head, ...body]);

    const columnsOtherFormats = columns.filter((col) => col?.exportXlsxFormat);
    columnsOtherFormats.forEach((column) => {
      const indexColFormat = head.findIndex(
        (colName) => column.name === colName
      );
      if (indexColFormat !== -1) {
        const xlsxColsNames = getXlxsColumnsName(workSheet, indexColFormat);

        xlsxColsNames.forEach((ref) => {
          workSheet[ref].z = column.exportXlsxFormat || '';
        });
      }
    });

    utils.book_append_sheet(workBook, workSheet, serializeSheetName(sheetName));
  });

  return writeFile(workBook, fileName);
}

export async function exportToXlsx(
  columns: readonly GridColumn<unknown>[],
  rows: readonly unknown[],
  fileName: string,
  sheetName = 'Sheet 1'
) {
  return exportToXlsxWithMultipleSheets(
    [{ columns, rows, sheetName }],
    fileName
  );
}

function applyConfigWorkBook(title: string) {
  const workBook = utils.book_new();
  if (!workBook.Props)
    workBook.Props = {
      Application: 'PLIM - Planejador Integrado de Midia',
      Comments: 'Arquivo gerado por: PLIM - Planejador Integrado de Midia',
      Author: 'Hub Digital - IM - Pré-venda',
      Title: title,
      Company: 'Globo Comunicação e Participações S/A',
      CreatedDate: new Date()
    };

  return workBook;
}

function applyStyle(mapColsXlsx: Readonly<MapCol[]>) {
  return (configModel: MapColData[]) => {
    const dataRow: WorkSheetObj = {};

    configModel.forEach((model) => {
      const ref = mapColsXlsx[model.cell.c].name;
      if (!dataRow[ref]) {
        dataRow[ref] = { t: 's', v: '' };
      }
      if (model.type) {
        dataRow[ref].t = model.type;
      }

      if (model.formula) {
        dataRow[ref].f = model.formula;
      }

      if (hasProp(model, 'value')) {
        dataRow[ref].v = model.value;
      }

      if (model.cellStyle) {
        dataRow[ref].s = model.cellStyle;
      }
    });

    return dataRow;
  };
}

function getRange(configModel: MapColData[]) {
  return configModel
    .filter((r) => r.range)
    .reduce<Range[]>((acc, val) => (val.range ? [...acc, val.range] : acc), []);
}

function applyConfigurations(
  modelAllCells: MapCell[],
  mapColsXlsx: Readonly<MapCol[]>
) {
  const modelXlsx = modelAllCells.map((model) => model.rowData);
  const workSheetStyles = modelXlsx.map(applyStyle(mapColsXlsx));

  const newWorkSheet = utils.json_to_sheet(workSheetStyles, {
    skipHeader: true,
    header: mapColsXlsx.map((col) => col.name),
    cellStyles: true
  });

  newWorkSheet['!merges'] = modelXlsx.flatMap(getRange);

  newWorkSheet['!cols'] = mapColsXlsx.map((col) => col.colInfo);

  newWorkSheet['!rows'] = modelAllCells.map((model) =>
    model?.rowInfo ? model.rowInfo : {}
  );

  return newWorkSheet;
}

export function exportToMapMedia(
  data: {
    modelAllCells: MapCell[];
    sheetName: string;
  }[],
  mapColsXlsx: Readonly<MapCol[]>,
  fileName: string
) {
  const workBook = applyConfigWorkBook(fileName.replace('.xlsx', ''));

  data.forEach(({ sheetName, modelAllCells }) => {
    const workSheet = applyConfigurations(modelAllCells, mapColsXlsx);

    utils.book_append_sheet(workBook, workSheet, serializeSheetName(sheetName));
  });

  return writeFile(workBook, fileName);
}

export function exportToPvi(title: string, pvi: any) {
  const fileString = ini.stringify(pvi);

  downloadFile(
    `simulation_${title}.pvi`,
    new Blob([fileString], {
      type: 'text/plain;charset=utf-8;'
    })
  );
}

export async function exportToGAds(
  title: string,
  pvi: PVIPlanningData[] | undefined
) {
  try {
    const response = await postExport({ pvi, title });

    const fileString = response?.data?.exportedTxt;

    downloadFile(
      `simulation_${title}.txt`,
      new Blob([fileString], {
        type: 'text/plain;charset=utf-8;'
      })
    );
  } catch (error) {
    throw new Error('Erro ao exportar para Google Ads');
  }
}

export async function exportDigitalSimulation(simulation: SimulationProps) {
  try {
    const digitalSimulationExport: IDigitalSimulationExport = {
      tableDate: simulation.groups?.[0]?.data?.cpm?.tableDate,
      campaignDate: {
        startDate: moment(simulation.DigitalDates?.startdate).format(
          'DD/MM/YYYY'
        ),
        endDate: moment(simulation.DigitalDates?.EndDate).format('DD/MM/YYYY')
      },

      groups: (simulation.groups as IGroup[]).map((item) => {
        const data = item.data as GroupData;
        const cpm = data.cpm as CpmData;
        const filter = data.filter.data as FiltersData;
        return {
          impressions: cpm.impressions as number,
          cpm: cpm.cpm as number,
          investment: cpm.investimentValue as number,
          format: 'GLOBO DAI SIMULCAST',
          seconds: cpm.secondaryCpm as number,
          segments: {
            ages: (filter.age as Age[]).map((g) => ({
              ...g,
              value: g.value.toString()
            })),
            genres: ([filter.gender] as Gender[]).map((g) => ({
              ...g,
              value: g.value.toString()
            })),
            interests: (filter.interests as Interest[]).map((g) => ({
              label: g.label,
              value: g.value.toString()
            })) as Location[],
            geolocations: (filter.locations as Location[]).map((g) => ({
              label: g.label,
              value: g.value.toString()
            })) as Interest[]
          }
        };
      })
    };

    const response = await postExportDigitalSimulation({
      simulation: digitalSimulationExport
    });

    const fileString = response.data;

    downloadFile(
      `Mapa de Mídia - Globoplay.xlsx`,
      new Blob([fileString], {
        type: response.headers['content-type']
      })
    );
  } catch (error) {
    toast.error('Ops, houve um problema ao gerar a planilha');
    throw new Error('Erro ao exportar');
  }
}
