export enum CpmsTypes {
  LOAD_CPMS_REQUEST = '@markets/LOAD_CPMS_REQUEST',
  LOAD_CPMS_SUCCESS = '@markets/LOAD_CPMS_SUCCESS',
  LOAD_CPMS_ERROR = '@markets/LOAD_CPMS_ERROR'
}
export interface CpmData {
  investimentValue: number;
  cpm?: number;
  secondaryCpm?: number;
  impressions?: number;
  tableDate?: Date;
}

export interface Cpm {
  secondary: number;
  cpm: number;
  tableDate: Date;
}

export interface CpmResponse {
  data: {
    rows: Cpm[];
    count: number;
  };
  status: number;
  msg: string;
}

export interface CpmAction {
  type: CpmsTypes;
  payload: {
    rows: Cpm[];
    count: number;
    message?: string;
  };
}

export interface CpmsState {
  readonly data: Cpm[];
  readonly loading: boolean;
  readonly error: boolean;
  readonly count: number;
}
