import './InvestimentTable.scss';
import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox
} from '@mui/material';
import { CpmData } from '../../../store/modules/Digital/Cpm/Cpm.types';

interface IcpmData {
  secondary: number;
  cpm: number;
  tableDate: Date;
}
interface InvestimentInputProps {
  cpmData: IcpmData[];
  investimentValue: number | undefined;
  sencondarySelected: number;
  cpmMaxValue: number;
  cpmMinValue: number;
  availableImpressions: number;
  groupId: number;
  onChangeValue: (
    investiment: number,
    secondary: number,
    impressions: number,
    cpm: number,
    groupId: number,
    tableDate: Date
  ) => void;
  groupCpm: CpmData | undefined;
  error: boolean;
}

const InvestimentTable = React.forwardRef(function InvestimentTable(
  props: InvestimentInputProps
) {
  const {
    cpmData,
    investimentValue,
    onChangeValue,
    sencondarySelected,
    cpmMaxValue,
    cpmMinValue,
    availableImpressions,
    groupId,
    groupCpm,
    error
  } = props;

  const [selectedSecondary, setSelectedSecondary] = useState<
    number | '' | null
  >('');

  const [canSelect, setCanSelect] = useState<boolean>(true);

  useEffect(() => {
    if (sencondarySelected !== 0) {
      setSelectedSecondary(sencondarySelected);
    }
  }, [sencondarySelected]);

  const calculatedImpressions = (cpm: number) => {
    if (investimentValue !== undefined && investimentValue >= cpm) {
      const impressions = Math.floor((investimentValue / cpm) * 1000);
      return impressions.toLocaleString('pt-BR');
    }
    return '-';
  };

  const calculatedImpressionsNumber = (cpm: number) => {
    if (investimentValue) {
      const impressions = Math.floor((investimentValue / cpm) * 1000);
      return impressions;
    }
    return 0;
  };

  const handleCheckboxChange = (
    secondary: number,
    cpm: number,
    tableDate: Date
  ) => {
    setSelectedSecondary(selectedSecondary === secondary ? null : secondary);
    const impressions = calculatedImpressionsNumber(cpm);
    if (investimentValue) {
      onChangeValue(
        investimentValue,
        secondary,
        impressions,
        cpm,
        groupId,
        tableDate
      );
    }
  };
  useEffect(() => {
    if (
      investimentValue === undefined ||
      investimentValue < cpmMaxValue ||
      (availableImpressions > 0 &&
        (investimentValue / cpmMinValue) * 1000 > availableImpressions)
    ) {
      setCanSelect(false);
      setSelectedSecondary('');
      handleCheckboxChange(0, 0, new Date());
    } else {
      setCanSelect(true);
      if (sencondarySelected !== 0) {
        setSelectedSecondary(sencondarySelected);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [investimentValue]);

  return (
    <>
      <div
        className={`investiment-table__title ${
          error && !groupCpm?.cpm ? 'investiment-table__error' : ''
        }`}
      >
        <h4>Selecione a secundagem do criativo para calcular o CPM</h4>
        {error && !groupCpm?.cpm && (
          <h3 className="investiment-table__error investiment-table__error__title">
            Escolher impressões ou secundagem do criativo
          </h3>
        )}
      </div>
      <TableContainer component={Paper}>
        <Table className="investiment-table">
          <TableHead>
            <TableRow>
              <TableCell> </TableCell>
              <TableCell>
                <h2>Secundagem</h2>
              </TableCell>
              <TableCell>
                <h2>CPM</h2>
              </TableCell>
              <TableCell>
                <h2>Impressões</h2>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cpmData?.map((row) => (
              <TableRow key={`investmentRow_${row.secondary}`}>
                <TableCell className="table-cell">
                  <Checkbox
                    disabled={!canSelect}
                    sx={{
                      '&.MuiCheckbox-root': {
                        borderRadius: '50%',
                        border: '2px solid transparent',
                        backgroundColor: 'white',
                        color: 'transparent',
                        cursor: canSelect === false ? 'not-allowed' : 'pointer'
                      },
                      '& .MuiSvgIcon-root': {
                        borderRadius: '50%',
                        border: '2px solid #E0E0E0',
                        backgroundColor: 'white',
                        cursor: canSelect === false ? 'not-allowed' : 'pointer'
                      },
                      '&.Mui-checked': {
                        color: 'transparent',
                        '& .MuiSvgIcon-root': {
                          borderRadius: '50%',
                          border: '6px solid #5D43FA',
                          backgroundColor: 'white'
                        }
                      }
                    }}
                    checked={selectedSecondary === row.secondary}
                    onChange={() =>
                      handleCheckboxChange(
                        row.secondary,
                        row.cpm,
                        row.tableDate
                      )
                    }
                  />
                </TableCell>
                <TableCell className="table-cell">
                  {row.secondary ? `${row.secondary} segundos` : '-'}
                </TableCell>
                <TableCell className="table-cell">
                  {row.cpm > 0
                    ? new Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL'
                      }).format(row.cpm)
                    : '-'}
                </TableCell>
                <TableCell>{calculatedImpressions(row.cpm)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
});

export default InvestimentTable;
